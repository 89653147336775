import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { MainTabs } from "@components/main-tabs";
import { PageTop } from "@components/page-top";
import { HeadeSearch } from "@components/head-search";
import { searchOptionsList, orderTypeList } from "./list-mock-data.js";
import { ProductList } from "@components/product-list";
import { PageFooter } from "@components/page-footer";
import { ListShow } from "@components/list-show";
import { Account, AccountStorage } from "@services/account";
import { ActiveInfo } from "@components/active-info";
var account = new Account();
var accountStorage = new AccountStorage();
var classification = "/pjyy-deepexi-product-center/api/v1/product/PcProduct/homeclassification";
var getProductByCatalogId = "/pjyy-deepexi-product-center/api/v1/background/PcCatalog/getProductByCatalogId";
export default {
  data: function data() {
    return {
      busy: true,
      //上拉的参数
      isShowSearch: false,
      isHover: true,
      searchOptionsList: searchOptionsList,
      orderTypeList: orderTypeList,
      productList: [],
      //数据
      checkoutOptionsList: {
        one: [],
        tow: [],
        three: []
      },
      // 选中分类的筛选
      isShowMore: false,
      isLogin: false,
      tabActive: -1,
      activeIdx: 0,
      ASC: true,
      isListMode: false,
      saleSort: "",
      //销量排序
      priceSort: "2",
      //价格排序
      page: 1,
      // 当前页
      totalPageNumber: 1,
      //总页数
      bottomTitle: "正在加载.....",
      totalNum: 0,
      //搜索到的商品数量

      paramsId: this.$route.params.id,
      level: this.$route.params.level,
      productName: "",
      //搜索商品名称
      isClickActive: false,
      //是否勾选了
      isExternal: false
    };
  },
  created: function created() {
    //this.tabActive = this.$route.query.activeIndex;
    this.isLogin = account.isLogined();
  },
  beforeRouteUpdate: function beforeRouteUpdate(to, from, next) {
    this.paramsId = to.params.id;
    this.level = to.params.level;
    this.getIntegralList();
    this.getClassification();
    next();
  },
  mounted: function mounted() {
    this.getIntegralList();
    this.getClassification();
    var userInfo = accountStorage.getCacheUserInfo();
    if (userInfo) {
      this.isExternal = userInfo.type == 1 ? true : false;
    }
  },
  methods: {
    toAddCart: function toAddCart() {
      this.$refs.handleGetSum.getSum();
    },
    handelImg: function handelImg() {
      this.$refs.handleGetSum.getSum();
    },
    // 是否勾选促销活动
    getSelectAll: function getSelectAll() {
      this.page = 1;
      this.productList = [];
      this.getIntegralList();
    },
    onSearchBlur: function onSearchBlur() {
      if (this.productName.length === 0) {
        this.isShowSearch = false;
      }
    },
    changeShow: function changeShow() {
      this.isShowSearch = !this.isShowSearch;
    },
    // 上拉加载更多
    loadMore: function loadMore() {
      this.busy = true;
      if (this.page <= this.totalPageNumber) {
        this.bottomTitle = "正在加载...";
        this.getIntegralList(true);
      }
    },
    // 获取分类筛选条件
    getClassification: function getClassification() {
      var _this = this;
      var data = {
        id: this.paramsId
      };
      this.$getHttpClient().post(classification, data).then(function (res) {
        // 商品分类
        var product = res.data.data.catalogConditions.map(function (item) {
          return {
            val: item.value,
            id: item.key,
            isCheck: false,
            classify: 1
          };
        });
        // 试剂
        var reagent = res.data.data.formulationConditions.map(function (item) {
          return {
            val: item.value,
            id: item.key,
            isCheck: false,
            classify: 2
          };
        });
        // 生产厂家
        // let manufacturer = res.data.data.factoryConditions.map(item => {
        //   return {
        //     val: item.value,
        //     id: item.key,
        //     isCheck: false,
        //     classify: 3
        //   };
        // });

        _this.searchOptionsList[0].options = product || [];
        _this.searchOptionsList[1].options = reagent || [];
        // this.searchOptionsList[2].options = manufacturer || [];
      });
    },
    // 获取数据
    getIntegralList: function getIntegralList() {
      var _this2 = this;
      var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var userInfo = accountStorage.getCacheUserInfo();
      console.log(userInfo);
      if (!flag) {
        this.page = 1;
      }
      var data = {
        page: this.page,
        pageSize: 20,
        id: this.paramsId,
        //大分类的三级分类id
        level: this.level,
        large: this.productName,
        sales: this.saleSort,
        prices: this.priceSort,
        productClassifyId: this.checkoutOptionsList.one.map(function (item) {
          return item.id;
        }),
        agentId: this.checkoutOptionsList.tow.map(function (item) {
          return item.id;
        }),
        factoryId: this.checkoutOptionsList.three.map(function (item) {
          return item.id;
        }),
        activityFlag: this.isClickActive ? 1 : 0
      };
      this.$getHttpClient().post(getProductByCatalogId, data).then(function (res) {
        _this2.page++;
        if (res.data.data.list.length <= 0 || !userInfo) {
          if (flag) {
            _this2.productList = _this2.productList.concat(res.data.data.list || []);
          } else {
            _this2.productList = res.data.data.list || [];
          }
          _this2.totalNum = res.data.data ? res.data.data.total : 0;
          _this2.totalPageNumber = Math.ceil(_this2.totalNum / 20);
          setTimeout(function () {
            _this2.busy = false;
          }, 1000);
          if (_this2.page > _this2.totalPageNumber) {
            _this2.bottomTitle = "数据已加载完";
            _this2.busy = true;
          }
        } else {
          var arr = [];
          res.data.data.list.forEach(function (item) {
            arr.push(item.productCode);
          });
          var params = {
            customer_code: userInfo.account,
            product_code_list: arr,
            currentPage: 1,
            pageSize: 20
          };
          if (flag) {
            _this2.productList = _this2.productList.concat(res.data.data.list || []);
          } else {
            _this2.productList = res.data.data.list || [];
          }
          _this2.totalNum = res.data.data ? res.data.data.total : 0;
          _this2.totalPageNumber = Math.ceil(_this2.totalNum / 20);
          setTimeout(function () {
            _this2.busy = false;
          }, 1000);
          if (_this2.page > _this2.totalPageNumber) {
            _this2.bottomTitle = "数据已加载完";
            _this2.busy = true;
          }
          //隐藏销售数量 --- 2021-06-09 update by lxr
          // this.$getHttpClient().post('/data-center/adm_oms_product_count',params).then(result=>{
          //   if(result.data.code==200){
          //     res.data.data.list.forEach(item=>{
          //       result.data.data.forEach(it=>{
          //         if(item.productCode==it.product_code){
          //           item.product_buy_num = it.product_buy_num
          //           item.product_buy_num_total = it.product_buy_num_total
          //         }
          //       })
          //     })
          //   }

          // })
        }
      });
    },
    // tab切换
    onClicktab: function onClicktab(event) {
      // window.sessionStorage.setItem("cindex",0);
      // if (window.sessionStorage.getItem("cindex")) {
      // } else {
      //   window.sessionStorage.setItem("cindex", 0);
      // }
      event.children.map(function (item) {
        item.pagenum = 0;
        item.datalist = [];
      });
      if (this.isExternal) {
        this.$buefy.toast.open({
          message: "您尚未成为正式会员，无法进入该页面",
          type: "is-danger"
        });
        this.$router.push({
          path: "/"
        });
      } else {
        this.$router.push({
          // path: "/channel/channel-detail/" + event.index + "/" +Number(window.sessionStorage.getItem("cindex"))
          path: "/channel/channel-detail/" + event.index + "/0"
        });
      }
    },
    // 点击综合排序、销量、价格排序
    changeOrder: function changeOrder(idx) {
      this.activeIdx = idx;
      // if (idx === 0) {
      //   // 综合排名
      //   this.page = 1;
      //   this.productList = [];
      //   this.ASC = true;
      //   this.getIntegralList();
      // }
      // if (idx === 1) {
      //   this.ASC = true;
      //   //按销量搜索
      //   this.page = 1;
      //   this.productList = [];
      //   this.saleSort = 1;
      //   this.priceSort = "";
      //   this.getIntegralList();
      // }
      // if (idx === 2) {
      //   // 按价格搜索
      //   this.saleSort = "";
      //   if (this.ASC) {
      //     this.page = 1;
      //     this.productList = [];
      //     this.priceSort = 2;
      //     this.getIntegralList();
      //   } else {
      //     this.page = 1;
      //     this.productList = [];
      //     this.priceSort = 1;
      //     this.getIntegralList();
      //   }
      //   this.ASC = !this.ASC;
      // }
      if (idx === 0) {
        this.ASC = true;
        this.isListMode = !this.isListMode;
        if (this.isListMode) {
          this.orderTypeList[idx] = "大图模式";
        } else {
          this.orderTypeList[idx] = "列表模式";
        }
      }
    },
    // 多选框勾选事件
    getSelectItem: function getSelectItem($event, item) {
      if (item.classify === 1) {
        if ($event) {
          this.checkoutOptionsList.one.push(item);
        } else {
          var index = this.checkoutOptionsList.one.indexOf(item.id);
          this.checkoutOptionsList.one.splice(index, 1);
        }
      }
      if (item.classify === 2) {
        if ($event) {
          this.checkoutOptionsList.tow.push(item);
        } else {
          var _index = this.checkoutOptionsList.tow.indexOf(item.id);
          this.checkoutOptionsList.tow.splice(_index, 1);
        }
      }
      // if (item.classify === 3) {
      //   if ($event) {
      //     this.checkoutOptionsList.three.push(item);
      //   } else {
      //     let index = this.checkoutOptionsList.three.indexOf(item.id);
      //     this.checkoutOptionsList.three.splice(index, 1);
      //   }
      // }
      this.page = 1;
      this.getIntegralList();
    },
    // 上边的分类点击拉出更多
    showMore: function showMore(index) {
      this.searchOptionsList[index].isShowMore = !this.searchOptionsList[index].isShowMore;
      this.$set(this.searchOptionsList, index, this.searchOptionsList[index]);
    }
  },
  components: {
    PageTop: PageTop,
    HeadeSearch: HeadeSearch,
    MainTabs: MainTabs,
    ProductList: ProductList,
    PageFooter: PageFooter,
    ListShow: ListShow,
    ActiveInfo: ActiveInfo
  }
};