var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "page-product-list"
  }, [_c('PageTop', {
    attrs: {
      "isLogin": _vm.isLogin
    }
  }), _c('HeadeSearch', {
    ref: "handleGetSum"
  }), _c('MainTabs', {
    attrs: {
      "isHover": _vm.isHover,
      "activeIndex": _vm.tabActive
    },
    on: {
      "getCurrentData": _vm.onClicktab
    }
  }), _c('div', {
    staticClass: "search-options-wrap"
  }, [_vm._l(_vm.searchOptionsList, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "search-option"
    }, [_c('div', {
      staticClass: "search-left"
    }, [_vm._v(_vm._s(item.title))]), _c('div', {
      class: [{
        autoCenter: item.isShowMore
      }, 'searrch-center'],
      style: {
        height: item.isShowMore ? 'auto' : '64px'
      }
    }, [_c('ul', {
      staticClass: "search-ul"
    }, _vm._l(item.options, function (option, index) {
      return _c('li', {
        key: index
      }, [_c('b-checkbox', {
        attrs: {
          "type": "is-info"
        },
        on: {
          "input": function input($event) {
            return _vm.getSelectItem($event, option);
          }
        },
        model: {
          value: option.isCheck,
          callback: function callback($$v) {
            _vm.$set(option, "isCheck", $$v);
          },
          expression: "option.isCheck"
        }
      }, [_vm._v(_vm._s(option.val))])], 1);
    }), 0)]), item.options.length > 6 ? _c('div', {
      staticClass: "search-right",
      on: {
        "click": function click($event) {
          return _vm.showMore(index);
        }
      }
    }, [_c('span', [_vm._v("更多")]), _c('i', {
      staticClass: "fa fa-caret-right",
      style: {
        transform: item.isShowMore ? 'rotate(-90deg)' : 'rotate(90deg)'
      },
      attrs: {
        "aria-hidden": "true"
      }
    })]) : _vm._e()]);
  }), _c('div', {
    staticClass: "search-total"
  }, [_vm.totalNum !== 0 ? _c('p', [_vm._v("共" + _vm._s(_vm.totalNum) + "件相关商品")]) : _c('p', {
    staticClass: "no-result"
  }, [_vm._v(" 没有找到相关的商品。 ")])])], 2), _c('div', {
    directives: [{
      name: "infinite-scroll",
      rawName: "v-infinite-scroll",
      value: _vm.loadMore,
      expression: "loadMore"
    }],
    attrs: {
      "infinite-scroll-disabled": "busy",
      "infinite-scroll-distance": "10"
    }
  }, [_c('div', {
    staticClass: "search-result-wrap"
  }, [_c('ActiveInfo'), _c('div', {
    staticClass: "result-order-top"
  }, [_c('ul', {
    staticClass: "result-order-wrap"
  }, [_vm._l(_vm.orderTypeList, function (item, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function click($event) {
          return _vm.changeOrder(index);
        }
      }
    }, [_c('span', {
      class: {
        active: index === _vm.activeIdx
      }
    }, [_vm._v(_vm._s(item))]), index === 2 ? _c('div', {
      staticClass: "angle"
    }, [_c('div', [_vm.activeIdx === 2 && !_vm.ASC ? _c('img', {
      attrs: {
        "src": require("./images/icon_bgs.png"),
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require("./images/icon_s.png"),
        "alt": ""
      }
    })]), _c('div', [_vm.activeIdx === 2 && _vm.ASC ? _c('img', {
      attrs: {
        "src": require("./images/icon_bgx.png"),
        "alt": ""
      }
    }) : _c('img', {
      attrs: {
        "src": require("./images/icon_x.png"),
        "alt": ""
      }
    })])]) : _vm._e()]);
  }), _c('li', [_c('p', {
    staticClass: "check"
  }, [_c('b-checkbox', {
    attrs: {
      "type": "is-info"
    },
    on: {
      "input": function input($event) {
        return _vm.getSelectAll();
      }
    },
    model: {
      value: _vm.isClickActive,
      callback: function callback($$v) {
        _vm.isClickActive = $$v;
      },
      expression: "isClickActive"
    }
  }, [_vm._v("促销活动商品")])], 1)])], 2), !_vm.isShowSearch ? _c('img', {
    staticClass: "icon-search",
    attrs: {
      "src": require("./images/icon_search.png"),
      "alt": ""
    },
    on: {
      "click": _vm.changeShow
    }
  }) : _c('div', {
    staticClass: "search-box"
  }, [_c('img', {
    attrs: {
      "src": require("./images/icon_search.png"),
      "alt": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productName,
      expression: "productName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "请输入"
    },
    domProps: {
      "value": _vm.productName
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.getIntegralList(false);
      },
      "blur": _vm.onSearchBlur,
      "input": function input($event) {
        if ($event.target.composing) return;
        _vm.productName = $event.target.value;
      }
    }
  })])]), !_vm.isListMode ? _c('ProductList', {
    attrs: {
      "productList": _vm.productList
    },
    on: {
      "handelImg": _vm.handelImg,
      "getGoodsList": _vm.getIntegralList
    }
  }) : _c('div', [_c('ListShow', {
    attrs: {
      "productList": _vm.productList,
      "isExternal": _vm.isExternal
    },
    on: {
      "toAddCart": _vm.toAddCart
    }
  })], 1), _vm.productList.length ? _c('div', {
    staticClass: "bottom-more-style"
  }, [_vm._v(" " + _vm._s(_vm.bottomTitle) + " ")]) : _vm._e()], 1)]), _vm.totalNum === 0 ? [_vm._m(0)] : _vm._e(), _c('PageFooter')], 2);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "no-result-wrap"
  }, [_c('img', {
    attrs: {
      "src": require("./images/no-result.png"),
      "alt": ""
    }
  })]);
}];
export { render, staticRenderFns };